<template>
  <div class="Sections">
    <draggable :element="'ul'" v-model="sections">
      <li v-for="(section, index) in sections" :key="index">
        <section-item :section="section" :sectionindex="index" />
      </li>
    </draggable>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import SectionItem from './section/SectionItem';

export default {
  name: 'Sections',
  components: {
    SectionItem,
    draggable,
  },
  data() {
    return {};
  },
  computed: {
    sections: {
      get() {
        return this.$store.getters.sections;
      },
      set(value) {
        this.$store.commit('updateSections', { value: value });
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheets/components/more-tab/sections/Sections.scss';
</style>
